import utils from "./KCUtils.js";
export default {
    getSwalYesNoOption() {
        return {
            confirmButtonColor: '#FF6633',
            cancelButtonColor: 'grey',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }
    },
    getColumnIndex(header,columnName,ignoreCase = false){
        if(ignoreCase){
            columnName = columnName.toLowerCase();
        }
        for(var x in header){
            let h = header[x];
            if(ignoreCase){
                h = h.toLowerCase();
            }
            if(h == columnName){
                return x;
            }
        }
        return -1;
    },
    // support ${x}   x-> column index start from 0
    createSubsitiueCommand(str,header){
        var ret = [];
        const regex = /\{([^}]+)\}/gm;
        let m;
        var start = 0;
        while ((m = regex.exec(str)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            //console.log(m);
            if(m.index != start){
                ret.push({
                    cmd: "keep",
                    value: str.slice(start,m.index),
                })
            }
            ret.push({
                cmd: "replace",
                value: m[0],
                index: this.getColumnIndex(header,m[1],true),
            })
            start = m.index+m[0].length;
        }
        if(start < str.length){
            ret.push({
                cmd: "keep",
                value: str.slice(start),
            })
        }
        //console.log(ret);
        return ret;
    },
    subsituteValue(row, cmd) {
        var ret = "";
        for(var i =0;i<cmd.length;i++){
            if(cmd[i].cmd == "keep"){
                ret += cmd[i].value;
            }else{
                if(cmd[i].index >=0){
                    ret += row[cmd[i].index];
                }else{
                    ret += cmd[i].value;
                }
            }
        }
        return ret;
    },
    calculatedValue(row, calculatedData, ignoreEmpty=true, currentValue = undefined) {
        var script = ""
        if (currentValue != undefined) {
            var x = currentValue;
            var $ = currentValue;
        }
        for (var i = 0; i < row.length; i++) {
            var value = utils.addslashes(row[i]);
            script += `var $${(i + 1)} = "${value}";\n`;
        }
        script += calculatedData;
        //console.log(script);
        var ret = eval(script);
        return ret;
    },
    convertColNameToColNum(script,header){
        var out = script;
        for (var i = 0; i < header.length; i++) {
            out = utils.ReplaceAll(out,`$["${header[i]}"]`,`$${(i + 1)}`);
        }
        return out;
    },
    convertColNumToColName(script,header){
        var out = script;
        for (var i = 0; i < header.length; i++) {
            out = utils.ReplaceAll(out,`$${(i + 1)}`,`$["${header[i]}"]`);
        }
        return out;
    },

}